import { useTranslation } from '@dreamstack/i18n'
import { rsTypeDetails } from '@dreamstack/real-estate-logic'
import filter from 'lodash/filter'
import map from 'lodash/map'
import { useMemo } from 'react'

export const useRsTypesForFilter = () => {
  const t = useTranslation()
  return useMemo(() => {
    let details = rsTypeDetails
    details = filter(details, ({ showAsSearchFilter }) => showAsSearchFilter)

    const allTypes = map(details, ({ rsType }) => {
      const typeToLower = rsType.toLowerCase()
      return { value: typeToLower, label: t(`accentro:rsTypes.${typeToLower}`) }
    })

    return [
      { value: 'all', label: t('accentro:rsTypes.allFilter') },
      ...allTypes,
    ]
  }, [t])
}
