import { useTranslation } from '@dreamstack/i18n'
import { SimpleCheckbox } from '@dreamstack/simple-components'
import map from 'lodash/map'
import type { FunctionComponent} from 'react';
import { useMemo } from 'react'
import type { PropertySearchFilterValues, RsCategory } from '../../index'

export const PropertyFilterRsCategory: FunctionComponent<React.PropsWithChildren<{
  localFilterState: PropertySearchFilterValues
  handleChangeFilterValue: (
    key: keyof PropertySearchFilterValues,
    newValue: any
  ) => void
}>> = ({ localFilterState, handleChangeFilterValue }) => {
  const t = useTranslation()

  const checkboxOptionsRsCategories: {
    label: string
    value: RsCategory
  }[] = useMemo(
    () => [
      { label: t('accentro:rsCategories:groundFloor'), value: 'groundFloor' },
      {
        label: t('accentro:rsCategories:raisedGroundFloor'),
        value: 'raisedGroundFloor',
      },
      { label: t('accentro:rsCategories:apartment'), value: 'apartment' },
      { label: t('accentro:rsCategories:roofStorey'), value: 'roofStorey' },
      { label: t('accentro:rsCategories:maisonette'), value: 'maisonette' },
    ],
    [t]
  )
  return (
    <>
      {map(checkboxOptionsRsCategories, (option) => {
        return (
          <div key={option.value}>
            <SimpleCheckbox
              checkBoxProps={{
                name: 'rsCategories',
                checked: localFilterState.rsCategories.includes(option.value),
                onChange: (e, checked) => {
                  let newValues = [...localFilterState.rsCategories]
                  if (checked) {
                    newValues.push(option.value)
                  } else {
                    newValues = newValues.filter(
                      (value) => value !== option.value
                    )
                  }
                  handleChangeFilterValue('rsCategories', newValues)
                },
              }}
              formControlProps={{
                value: option.value,
                label: t(`accentro:rsCategories:${option.value}`),
                name: 'rsCategories',
              }}
            />
          </div>
        )
      })}
    </>
  )
}
