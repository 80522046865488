import { useLocale, useTranslation } from '@dreamstack/i18n'
import { SimpleInput } from '@dreamstack/simple-components'
import type { InputProps } from '@material-ui/core'
import { InputAdornment, Slider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import isEqual from 'lodash/isEqual'
import throttle from 'lodash/throttle'
import type { EventHandler } from 'react'
import React, { useCallback, useEffect, useState } from 'react'
import 'twin.macro'
import tw from 'twin.macro'
import { HeadingH4 } from '../../index'

const useStyles = makeStyles((theme) => ({
  inputValues: {
    '& input': {
      fontWeight: 600,
    },
    '& p': {
      fontWeight: 600,
      color: '#2b2d36',
    },
    [theme.breakpoints.down('md')]: {
      '& input': {
        fontSize: '16px',
      },
      '& p': {
        fontSize: '13px',
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& input': {
        fontSize: '20px',
      },
      '& p': {
        fontSize: '16px',
      },
    },
  },
}))

const InputSpacer = tw.div`border-b-2 border-accentroGray-full  h-0 mx-px-8 w-px-16 md:(w-px-24  mx-px-16) lg:(w-px-32  mx-px-24)`
const InputArea = tw.div`flex content-center justify-between items-center`

const useReplaceThousandSeperator = () => {
  const locale = useLocale()

  return useCallback(
    (value: string) => {
      const formattedValue =
        locale === 'de' ? value.replace(/\./g, '') : value.replace(/,/g, '')
      return formattedValue
    },
    [locale]
  )
}

const SimpleFilterInput = ({
  onChange,
  value,
  inputProps,
  type,
}: {
  onChange: (value: string) => void
  value: number | string
  inputProps: InputProps
  type: 'min' | 'max'
}) => {
  const t = useTranslation()
  const locale = useLocale()
  const anyValueString = t('accentro:propertyFilter.anyValue')
  const maxValue = inputProps.inputProps?.max

  // if input value is at max display 'any value' string. The filter logic will ignore the max value
  const valueParsed: number = parseInt(`${value}`)
  if (isNaN(valueParsed)) {
    value = anyValueString
  }
  if (type === 'max' && valueParsed >= maxValue) value = anyValueString
  if (value !== anyValueString)
    value = value.toLocaleString(locale === 'de' ? 'de-DE' : 'en-GB')

  const handleFocus: EventHandler<any> = (event) => {
    // TODO: Fix types. Tim and I hab no idea on how to fix this. :-(
    event.target.select()
  }
  return (
    <SimpleInput
      // type="number"
      InputProps={{ ...inputProps, disableUnderline: true }}
      onChange={(e) => onChange(e.target.value)}
      onClick={handleFocus}
      value={value}
      variant="standard"
      tw="bg-accentroGray-50 w-full flex-1"
      size="medium"
    />
  )
}

export const FilterWithSliderTemplate = ({
  title,
  subTitle,
  value,
  onChange,
  min,
  max,
  step,
  valueLabelFormat,
  endAdornment,
}: {
  title: string
  subTitle?: string
  value: [number, number]
  onChange: (value: any) => void
  min: number
  max: number
  step?: number
  endAdornment?: string
  valueLabelFormat?:
    | string
    | ((value: number, index: number) => React.ReactNode)
}) => {
  const inputProps: InputProps = {
    inputProps: { min, max, step: step || 1, inputMode: 'numeric' },
    style: { width: '100%', padding: '4px 8px' },
    endAdornment: endAdornment && (
      <InputAdornment position="end">{endAdornment}</InputAdornment>
    ),
  }
  const [sliderValue, setSliderValue] = useState<number | number[]>(value)
  const replaceThousandSeperator = useReplaceThousandSeperator()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const syncValues = useCallback(
    throttle(
      (value, sliderValue) => {
        if (!isEqual(value, sliderValue)) {
          setSliderValue([+value[0], +value[1]])
        }
      },
      100,
      {
        leading: false,
      }
    ),
    []
  )
  // This effect takes care of delayed applied url filter value to match then the slider state.
  useEffect(() => {
    syncValues(value, sliderValue)
  }, [syncValues, value, sliderValue])

  const handleSliderChange = useCallback(
    (_e: unknown, v: number | number[]) => {
      setSliderValue(v)
      onChange(v)
    },
    [setSliderValue, onChange]
  )

  const classes = useStyles()

  return (
    <>
      <HeadingH4>
        {title}
        {subTitle && <span tw="font-semibold"> {subTitle}</span>}
      </HeadingH4>
      <div tw="px-px-8">
        <Slider
          value={sliderValue}
          onChange={handleSliderChange}
          valueLabelFormat={valueLabelFormat}
          aria-labelledby="range-slider"
          // getAriaValueText={valuetext}
          min={min}
          max={max}
          step={step || 1}
        />
      </div>
      <InputArea className={classes.inputValues}>
        <SimpleFilterInput
          onChange={(newValue) => {
            if (!isNaN(parseInt(newValue))) {
              //remove thousand seperator from string so that value can be parsed correctly
              const formattedNewValue = replaceThousandSeperator(newValue)
              onChange([parseInt(formattedNewValue), value[1]])
              setSliderValue([parseInt(formattedNewValue), value[1]])
            } else {
              onChange([min, value[1]])
              setSliderValue([min, value[1]])
            }
          }}
          value={value[0]}
          inputProps={inputProps}
          type="min"
        />
        <InputSpacer />
        <SimpleFilterInput
          onChange={(newValue) => {
            if (!isNaN(parseInt(newValue))) {
              //remove thousand seperator from string so that value can be parsed correctly
              const formattedNewValue = replaceThousandSeperator(newValue)
              onChange([value[0], parseInt(formattedNewValue)])
              setSliderValue([value[0], parseInt(formattedNewValue)])
            } else {
              onChange([value[0], max])
              setSliderValue([value[0], max])
            }
          }}
          value={value[1]}
          inputProps={inputProps}
          type="max"
        />
      </InputArea>
    </>
  )
}
