import { useTranslation } from '@dreamstack/i18n'
import {
  SimpleCheckbox,
  SimpleInput,
  SimpleRadio,
} from '@dreamstack/simple-components'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import map from 'lodash/map'
import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import 'twin.macro'
import tw, { css, styled } from 'twin.macro'
import { ChevronDown } from '../../../../icons/src/icon-components/ChevronDown'
import { ChevronRight } from '../../../../icons/src/icon-components/ChevronRight'
import { usePageYOffset } from '../../hooks/usePageYOffset'
import type { PropertySearchFilterValues, RentedTypes } from '../../index'
import {
  HeadingH4,
  MaxFilterValue,
  SimpleMuiButton,
  useIsLgOrGreater,
  useIsMdOrGreater,
  useIsReservationUi,
  useIsSmOrGreater,
  useIsSmOrSmaller,
  useResponsiveNavbarVariants,
  useWindowSize,
} from '../../index'
import { usePropertySearchContext } from '../../next/PropertySearchContext'
import { PropertySearchAutocomplete } from '../PropertySearchAutocomplete'
import { PropertyFilterConstructionAgeType } from './PropertyFilterConstructionAgeType'
import { PropertyFilterImmoTypes } from './PropertyFilterImmoTypes'
import { PropertyFilterOtherFilters } from './PropertyFilterOtherFilters'
import { PropertyFilterRsCategory } from './PropertyFilterRsCategory'
import { FilterWithSliderTemplate } from './PropertyFilterWithSliderTemplate'
import { SaveSearchButton } from './SaveSearchButton'

const scrollBarStyles = css`
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(43, 45, 54, 0.05);
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(43, 45, 54, 0.1);
  }
`
const FilterEntryContainer = tw.div`pt-px-24 lg:(pt-px-32)`
const PropertyFilterContainer = styled.div(() => [
  tw`w-full md:(overflow-y-auto overflow-x-hidden sticky top-px-96 pb-px-128 pr-px-16)`,
  scrollBarStyles,
])

const HideFilterStatesInitials = [
  {
    id: 'rented' as const,
    hide: true,
  },
  {
    id: 'constructionAgeType' as const,
    hide: true,
  },
  {
    id: 'rsCategory' as const,
    hide: true,
  },
  {
    id: 'otherFilters' as const,
    hide: true,
  },
]
type HideFilterIdsType = typeof HideFilterStatesInitials[number]['id']

const HideableFilterEntry = styled.div(({ hide }: { hide: boolean }) => [
  tw`flex flex-col`,
  hide && tw`hidden`,
])

export const useRadioOptionsRented: () => {
  label: string
  value: RentedTypes
}[] = () => {
  const t = useTranslation()
  return [
    { label: t('accentro:rented'), value: 'rented' },
    { label: t('accentro:not-rented'), value: 'not-rented' },
    { label: t('accentro:both'), value: 'both' },
  ]
}

export const PropertyFilter: FunctionComponent<
  React.PropsWithChildren<{}>
> = () => {
  const t = useTranslation()

  const isReservationUi = useIsReservationUi()

  // TODO: get default values
  // view State for immediate state updates on dragging the slider - later debounced sync with filter state
  const {
    setPropertySearchFilterValues,
    propertySearchFilterValues,
    betterDefaultFilterValues,
    propertySearchReservationUiFilter,
    setPropertySearchReservationUiFilter,
  } = usePropertySearchContext()

  const [localFilterState, setLocalFilterState] =
    useState<PropertySearchFilterValues>({
      ...propertySearchFilterValues,
    })

  useEffect(() => {
    setLocalFilterState(propertySearchFilterValues)
  }, [propertySearchFilterValues])

  // debounced sync with filter state
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const syncState = useCallback(
    debounce((newSearchFilterValue) => {
      setPropertySearchFilterValues(newSearchFilterValue)
    }, 500),
    [setPropertySearchFilterValues]
  )

  const handleChangeFilterValue = useCallback(
    (key: keyof PropertySearchFilterValues, newValue: any) => {
      //set local filterstate to immediatly update ui
      setLocalFilterState((localFilterState) => ({
        ...localFilterState,
        [key]: newValue,
      }))

      //sync state delayed to not trigger immediate url updates on slider dragging or typing
      syncState({ [key]: newValue })
    },
    [setLocalFilterState, syncState]
  )

  const radioOptionsRented = useRadioOptionsRented()

  const { isAtTop } = usePageYOffset()
  const { height } = useWindowSize()
  const isLg = useIsLgOrGreater()
  const isMd = useIsMdOrGreater()
  const isSm = useIsSmOrGreater()
  const isMobile = useIsSmOrSmaller()
  const navbarElementVariants = useResponsiveNavbarVariants()

  const [hideFilterState, setHideFilterState] = useState(
    HideFilterStatesInitials
  )

  const getHideStateById = (id: HideFilterIdsType) => {
    return find(hideFilterState, (hideState) => hideState.id === id)?.hide
  }

  const toggleHideFilterState = useCallback(
    (id: HideFilterIdsType) => {
      setHideFilterState((hideFilterState) => {
        const newHideFilterState = map(hideFilterState, (filterState) => {
          if (filterState.id === id) {
            return {
              ...filterState,
              hide: !filterState.hide,
            }
          }
          return filterState
        })
        return newHideFilterState
      })
    },
    [setHideFilterState]
  )

  const HideableFilterEntryTitle = ({
    title,
    keyOfEntry,
  }: {
    title: string
    keyOfEntry: HideFilterIdsType
  }) => {
    const hide = getHideStateById(keyOfEntry)
    const Icon = hide ? <ChevronRight /> : <ChevronDown />
    return (
      <>
        <HeadingH4
          onClick={() => toggleHideFilterState(keyOfEntry)}
          tw="cursor-pointer flex items-center -ml-px-6 gap-px-8 mb-px-8 md:(mb-px-16)"
        >
          {Icon}
          {title}
        </HeadingH4>
      </>
    )
  }

  const propertyFilterHeight = !height
    ? '100vh'
    : isAtTop
    ? (height - navbarElementVariants.isAtTop.height - 16).toString() + 'px' // we have to remove the 16px for the distanz to the bottom
    : (height - navbarElementVariants.isAtTop.height + 84).toString() + 'px' // due to the fact that the navbarspaceblocker is less in height we have to resize it at all

  return (
    <PropertyFilterContainer css={{ maxHeight: `${propertyFilterHeight}` }}>
      {isReservationUi && (
        <FilterEntryContainer>
          <HeadingH4 tw="pb-px-12">Suche</HeadingH4>
          <div tw="flex flex-col">
            <SimpleInput
              // label="Suche"
              variant="standard"
              InputProps={{
                style: { padding: '4px 8px' },
                disableUnderline: true,
              }}
              value={localFilterState.textSearch}
              onChange={(e) => {
                handleChangeFilterValue('textSearch', e.target.value)
              }}
              tw="bg-accentroGray-50 w-full flex-1"
              size="medium"
            />
          </div>
        </FilterEntryContainer>
      )}
      <FilterEntryContainer>
        <HeadingH4 tw="pb-px-12">
          {t('accentro:propertySearchFilter.location')}
        </HeadingH4>
        <PropertySearchAutocomplete displayArrow />
      </FilterEntryContainer>
      <FilterEntryContainer>
        <HeadingH4 tw="pb-px-12">{t('accentro:typeOfImmo')}</HeadingH4>
        <PropertyFilterImmoTypes
          currentActiveValue={localFilterState.typeOfImmo}
          onChange={(v) => handleChangeFilterValue('typeOfImmo', v)}
        />
      </FilterEntryContainer>
      <FilterEntryContainer>
        <FilterWithSliderTemplate
          title={t('accentro:room.rooms')}
          value={localFilterState.roomRange}
          onChange={(v) => handleChangeFilterValue('roomRange', v)}
          min={0}
          max={MaxFilterValue.roomRange}
        />
      </FilterEntryContainer>
      <FilterEntryContainer>
        <FilterWithSliderTemplate
          title={t('accentro:living-space')}
          subTitle={`in ${t('accentro:squareMeter')}`}
          value={localFilterState.livingspace}
          onChange={(v) => handleChangeFilterValue('livingspace', v)}
          min={0}
          max={MaxFilterValue.livingSpace}
          endAdornment={t('accentro:squareMeter')}
        />
      </FilterEntryContainer>
      <FilterEntryContainer>
        <FilterWithSliderTemplate
          title={t('accentro:purchase-price')}
          subTitle={'in EUR'}
          value={localFilterState.priceRange}
          onChange={(v) => handleChangeFilterValue('priceRange', v)}
          min={0}
          max={MaxFilterValue.price}
          step={1000}
          valueLabelFormat={(value) => `${Math.round(value / 1000)}`}
          endAdornment="€"
        />
      </FilterEntryContainer>
      <FilterEntryContainer>
        <HideableFilterEntryTitle
          title={t('accentro:renting-state')}
          keyOfEntry="rented"
        />
        <HideableFilterEntry hide={!!getHideStateById('rented')}>
          <SimpleRadio
            // ref={register}
            radioGroupProps={{
              value: localFilterState.rented,
              onChange: (e, v) => handleChangeFilterValue('rented', v),
              defaultValue: 'both',
              name: 'rented',
              row: false,
            }}
            options={radioOptionsRented}
          />
        </HideableFilterEntry>
      </FilterEntryContainer>
      <FilterEntryContainer>
        <HideableFilterEntryTitle
          title={t('accentro:constructionAgeType:constructionAgeType')}
          keyOfEntry="constructionAgeType"
        />

        <HideableFilterEntry hide={!!getHideStateById('constructionAgeType')}>
          <PropertyFilterConstructionAgeType
            localFilterState={localFilterState}
            handleChangeFilterValue={handleChangeFilterValue}
          />
        </HideableFilterEntry>
      </FilterEntryContainer>
      <FilterEntryContainer>
        <HideableFilterEntryTitle
          title={t('accentro:rsCategories:title')}
          keyOfEntry="rsCategory"
        />

        <HideableFilterEntry hide={!!getHideStateById('rsCategory')}>
          <PropertyFilterRsCategory
            localFilterState={localFilterState}
            handleChangeFilterValue={handleChangeFilterValue}
          />
        </HideableFilterEntry>
      </FilterEntryContainer>
      <FilterEntryContainer>
        <HideableFilterEntryTitle
          title={t('accentro:otherFilters:title')}
          keyOfEntry="otherFilters"
        />

        <HideableFilterEntry hide={!!getHideStateById('otherFilters')}>
          <PropertyFilterOtherFilters
            localFilterState={localFilterState}
            handleChangeFilterValue={handleChangeFilterValue}
          />
        </HideableFilterEntry>
      </FilterEntryContainer>
      <div tw="mt-px-48">
        <SaveSearchButton />
      </div>
      <div tw="mt-px-24">
        <SimpleMuiButton
          onClick={() => {
            setLocalFilterState(betterDefaultFilterValues)
            setPropertySearchFilterValues(betterDefaultFilterValues)
          }}
          variant="text"
          color="primary"
        >
          {t('accentro:reset')}
        </SimpleMuiButton>
      </div>
      {isReservationUi && (
        <FilterEntryContainer>
          <HeadingH4>Angebote</HeadingH4>
          <div tw="flex flex-col">
            <SimpleCheckbox
              checkBoxProps={{
                name: 'showHidden',
                checked: propertySearchReservationUiFilter?.showFree,
                onChange: (e, checked) =>
                  setPropertySearchReservationUiFilter((current) => ({
                    ...current,
                    showFree: checked,
                  })),
              }}
              formControlProps={{
                value: propertySearchReservationUiFilter.showFree,
                label: 'Freie Angebote',
                name: 'showHidden',
              }}
            />
            <SimpleCheckbox
              checkBoxProps={{
                name: 'showHidden',
                checked: propertySearchReservationUiFilter?.showMyReservations,
                onChange: (e, checked) =>
                  setPropertySearchReservationUiFilter((current) => ({
                    ...current,
                    showMyReservations: checked,
                  })),
              }}
              formControlProps={{
                value: propertySearchReservationUiFilter.showMyReservations,
                label: 'Meine Reservierungen',
                name: 'showMyReservations',
              }}
            />
            <SimpleCheckbox
              checkBoxProps={{
                name: 'showHidden',
                checked: propertySearchReservationUiFilter?.showHidden,
                onChange: (e, checked) =>
                  setPropertySearchReservationUiFilter((current) => ({
                    ...current,
                    showHidden: checked,
                  })),
              }}
              formControlProps={{
                value: propertySearchReservationUiFilter.showHidden,
                label: 'Reservierte Angebote',
                name: 'showHidden',
              }}
            />
          </div>
        </FilterEntryContainer>
      )}
    </PropertyFilterContainer>
  )
}
