import { SimpleSelect } from '@dreamstack/simple-components'
import { MenuItem } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import type { FunctionComponent } from 'react'
import 'twin.macro'
import { useRsTypesForFilter } from './useRsTypesForFilter'

const useStyles = makeStyles((theme) =>
  createStyles({
    select: {
      fontSize: '20px',
      fontWeight: 600,
    },
  })
)

export const PropertyFilterImmoTypes: FunctionComponent<React.PropsWithChildren<{
  currentActiveValue: string
  onChange: (newValue: unknown) => void
}>> = ({ currentActiveValue, onChange }) => {
  const allOptions = useRsTypesForFilter()
  const allOptionsSortByName = sortBy(allOptions, (option) => option.label)
  const classes = useStyles()

  return (
    <div tw="w-full">
      <SimpleSelect
        formControlProps={{ variant: 'standard', fullWidth: true }}
        selectProps={{
          fullWidth: true,
          value: currentActiveValue,
          onChange: (e) => onChange(e.target.value),
          style: {
            fontSize: '20px',
            fontWeight: 600,
          },
        }}
      >
        {map(allOptionsSortByName, (option) => (
          <MenuItem
            className={classes.select}
            value={option.value}
            key={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </SimpleSelect>
    </div>
  )
}
