import { useTranslation } from '@dreamstack/i18n'
import { trpc } from '@dreamstack/trpc/client'
import type { FunctionComponent } from 'react'
import { useHandleAuthFlowForAction } from '../../../../auth/src/lib/hooks'
import { useNotificationPopover } from '../../hooks/useNotificationPopover'
import { SimpleMuiButton, useMyAccentroBaseUrl } from '../../index'
import { usePropertySearchContext } from '../../next/PropertySearchContext'
import { useGetPropstackSavedQueryFromFilter } from '../../savedSearch/useGetPropstackSavedQueryFromFilter'

export const SaveSearchButton: FunctionComponent<
  React.PropsWithChildren<{}>
> = () => {
  const { propertySearchFilterValues } = usePropertySearchContext()
  const { mutateAsync: upsertQueryMutation } =
    trpc.savedQueries.upsertSavedQuery.useMutation()

  const t = useTranslation()
  const transformFilterToPropstackSavedQuery =
    useGetPropstackSavedQueryFromFilter()

  const myAccentroLink = useMyAccentroBaseUrl()

  const {
    NotificationPopoverComponent,
    setReferenceElement,
    openNotification,
  } = useNotificationPopover({
    buttonHref: `/${myAccentroLink}`,
    buttonText: t('accentro:my.mySearchList'),
    title: t('accentro:my.addedSearch'),
    type: 'success',
    options: { placement: 'bottom' },
    offset: [0, -80],
  })
  const handleAuth = useHandleAuthFlowForAction()

  const onSaveSearch = () => {
    const actionCallback = async () => {
      const queryVars = transformFilterToPropstackSavedQuery({
        filter: propertySearchFilterValues,
      })
      openNotification()
      const data = await upsertQueryMutation({
        query: queryVars,
      })
    }
    handleAuth({
      actionCallback,
    })
  }

  return (
    <>
      <div>
        <SimpleMuiButton
          ref={setReferenceElement}
          onClick={onSaveSearch}
          variant="outlined"
          color="secondary"
        >
          {t('accentro:my.addNewSearch')}
        </SimpleMuiButton>
      </div>
      {NotificationPopoverComponent}
    </>
  )
}

export const getIsRented = (rented: string | undefined | null) => {
  switch (rented) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return null
  }
}
