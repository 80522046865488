import { useTranslation } from '@dreamstack/i18n'
import { SimpleRadio } from '@dreamstack/simple-components'
import type { FunctionComponent} from 'react';
import { useMemo } from 'react'
import type {
  ConstructionAgeType,
  PropertySearchFilterValues,
} from '../../index'

export const PropertyFilterConstructionAgeType: FunctionComponent<React.PropsWithChildren<{
  localFilterState: PropertySearchFilterValues
  handleChangeFilterValue: (
    key: keyof PropertySearchFilterValues,
    newValue: any
  ) => void
}>> = ({ localFilterState, handleChangeFilterValue }) => {
  const t = useTranslation()

  const radioOptionsConstructionAgeType: {
    label: string
    value: ConstructionAgeType
  }[] = useMemo(
    () => [
      {
        label: t('accentro:constructionAgeType:oldbuilding'),
        value: 'oldbuilding',
      },
      {
        label: t('accentro:constructionAgeType:newbuilding'),
        value: 'newbuilding',
      },
      { label: t('accentro:constructionAgeType:both'), value: 'both' },
    ],
    [t]
  )
  return (
    <SimpleRadio
      radioGroupProps={{
        value: localFilterState.constructionAgeType,
        onChange: (e, v) => handleChangeFilterValue('constructionAgeType', v),
        defaultValue: 'both',
        name: 'constructionAgeType',
        row: false,
      }}
      options={radioOptionsConstructionAgeType}
    />
  )
}
