import { useTranslation } from '@dreamstack/i18n'
import { SimpleCheckbox } from '@dreamstack/simple-components'
import map from 'lodash/map'
import type { FunctionComponent} from 'react';
import { useMemo } from 'react'
import type {
  OtherFilterOptions,
  PropertySearchFilterValues,
} from '../../index'

export const PropertyFilterOtherFilters: FunctionComponent<React.PropsWithChildren<{
  localFilterState: PropertySearchFilterValues
  handleChangeFilterValue: (
    key: keyof PropertySearchFilterValues,
    newValue: any
  ) => void
}>> = ({ localFilterState, handleChangeFilterValue }) => {
  const t = useTranslation()

  const checkboxOptionsOtherFilters: {
    label: string
    value: OtherFilterOptions
  }[] = useMemo(
    () => [
      { label: t('accentro:otherFilters:waterClose'), value: 'waterClose' },
      { label: t('accentro:otherFilters:lift'), value: 'lift' },
      {
        label: t('accentro:otherFilters:balconyTerrace'),
        value: 'balconyTerrace',
      },
    ],
    [t]
  )
  return (
    <>
      {map(checkboxOptionsOtherFilters, (option) => {
        return (
          <div key={option.value}>
            <SimpleCheckbox
              checkBoxProps={{
                name: 'otherFilters',
                checked: localFilterState.otherFilters.includes(option.value),
                onChange: (e, checked) => {
                  let newValues = [...localFilterState.otherFilters]
                  if (checked) {
                    newValues.push(option.value)
                  } else {
                    newValues = newValues.filter(
                      (value) => value !== option.value
                    )
                  }
                  handleChangeFilterValue('otherFilters', newValues)
                },
              }}
              formControlProps={{
                value: option.value,
                label: t(`accentro:otherFilters:${option.value}`),
                name: 'otherFilters',
              }}
            />
          </div>
        )
      })}
    </>
  )
}
